// eslint-disable-next-line no-restricted-imports
import 'rxjs/Rx';

import get from 'lodash/get';
import inRange from 'lodash/inRange';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
// eslint-disable-next-line no-restricted-imports
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import qs from 'qs';
import raven from 'raven-js';

import config from '../../../config';
import { formatPrettyPath } from '../../../helpers/navigation';
import { DEFAULT_LANGUAGE } from '../../../i18n';
import * as actions from '../../actions';
import type { PrefetchedData } from '../../reducers/preFetchedData';
import { BOOT_ERRORS, REQUEST_STATUS } from '../../selectors';

import getCategoryPagesAsPromise from './getCategoryPagesAsPromise';
import getChapterAsPromise from './getChapterAsPromise';
import getCourseAsPromise from './getCourseAsPromise';
import { getCourseImagesAsPromise } from './getCourseImagesAsPromise';
import getExercisesAsPromise from './getExercisesAsPromise';
import getIsLtiErrorAsPromise from './getIsLtiErrorAsPromise';
import { getLearningRecapAsPromise } from './getLearningRecapAsPromise';
import getProgressAsPromise from './getProgressAsPromise';
import getTranslatedCoursesAsPromise from './getTranslatedCoursesAsPromise';
import getUserAsPromise from './getUserAsPromise';
import getWorkspaceTemplateAsPromise from './getWorkspaceTemplateAsPromise';
import initSplitAsPromise from './initSplitAsPromise';

const getRef = (courseOrChapter: any) =>
  courseOrChapter.slug || courseOrChapter.id;
const isValidFeatureFlag = (value: any) => isString(value) && !isEmpty(value);

export type Result<T> = { data: T; type: 'ok' } | { error: any; type: 'error' };

const promiseResolver = async <T>(promise: Promise<T>): Promise<Result<T>> => {
  try {
    const data = await promise;
    return { data, type: 'ok' };
  } catch (error) {
    return { error, type: 'error' };
  }
};

export const epicBoot = (action$: any) =>
  action$.ofType(actions.BOOT).switchMap(async (action: any) => {
    const preFetchedData: PrefetchedData = {};
    const {
      chapterRef,
      courseRef,
      isPreview,
      language,
      originalLocation,
      queryParams,
    } = action;

    const overwrittenFeatureFlags = pickBy(
      get(action, ['queryParams', 'ff'], null),
      isValidFeatureFlag,
    );

    let exerciseNumber;
    try {
      exerciseNumber = parseInt(action.queryParams.ex, 10);
    } catch (error) {
      exerciseNumber = 1;
    }

    const courseAsPromise = getCourseAsPromise({
      isPreview,
      courseRef,
      preFetchedData: action.preFetchedData,
      language,
    });
    const chapterAsPromise = getChapterAsPromise({
      courseAsPromise,
      chapterRef,
      preFetchedData: action.preFetchedData,
    });
    const exercisesAsPromise = getExercisesAsPromise({
      exerciseProgrammingLanguageOverride: queryParams.programming_language,
      isPreview,
      courseAsPromise,
      chapterAsPromise,
      preFetchedData: action.preFetchedData,
      language,
    });
    const courseImagesAsPromise = getCourseImagesAsPromise({
      courseAsPromise,
      preFetchedData: action.preFetchedData,
    });
    const workspaceTemplatePromise = getWorkspaceTemplateAsPromise({
      courseAsPromise,
      preFetchedData: action.preFetchedData,
    });

    const categoryPagesPromise = getCategoryPagesAsPromise({
      preFetchedData: action.preFetchedData,
    });

    const translatedCoursesPromise = getTranslatedCoursesAsPromise({
      preFetchedData: action.preFetchedData,
    });

    let userAsPromise;
    let isLtiErrorAsPromise;
    let progressAsPromise;
    let splitInitializationAsPromise;
    let learningRecapPromise;
    if (config.isServerSideRendering) {
      userAsPromise = Promise.resolve(undefined);
      isLtiErrorAsPromise = Promise.resolve(false);
      progressAsPromise = Promise.resolve({
        courseProgress: undefined,
        chapterProgress: undefined,
        streakInfo: undefined,
      });
      splitInitializationAsPromise = Promise.resolve(undefined);
      learningRecapPromise = Promise.resolve(null);
    } else {
      userAsPromise = getUserAsPromise();
      isLtiErrorAsPromise = getIsLtiErrorAsPromise({
        isPreview,
        skipLtiCheck: action.queryParams.skip_lti_check === '1',
        courseAsPromise,
        chapterAsPromise,
      });
      progressAsPromise = getProgressAsPromise({
        isPreview,
        userAsPromise,
        courseAsPromise,
        chapterAsPromise,
      });
      splitInitializationAsPromise = initSplitAsPromise({
        userAsPromise,
        courseAsPromise,
        overwrittenFeatureFlags,
      });
      learningRecapPromise = getLearningRecapAsPromise({
        courseAsPromise,
        chapterAsPromise,
        exerciseNumber,
        exercisesAsPromise,
        isPreview,
        preFetchedData: action.preFetchedData,
        language,
      });
    }

    const courseResult = await promiseResolver<any>(courseAsPromise);
    const chapterResult = await promiseResolver(chapterAsPromise);
    const exercisesResult = await promiseResolver(exercisesAsPromise);

    if (
      exercisesResult.type === 'ok' &&
      !inRange(exerciseNumber - 1, exercisesResult.data.length)
    ) {
      exerciseNumber = 1;
    }

    const workspaceTemplateResult = await promiseResolver(
      workspaceTemplatePromise,
    );
    const learningRecapResult = await promiseResolver(learningRecapPromise);
    const courseImagesResult = await promiseResolver(courseImagesAsPromise);
    const userResult = await promiseResolver<any>(userAsPromise);

    const userId =
      userResult.type === 'ok' ? userResult.data?.settings?.id : null;
    if (!config.isServerSideRendering && userId != null) {
      raven.setUserContext({ id: `${userId}` });
    }

    const isLtiErrorResult = await promiseResolver(isLtiErrorAsPromise);
    const progressResult = await promiseResolver<any>(progressAsPromise);
    const categoryPagesResult = await promiseResolver(categoryPagesPromise);
    const translatedCoursesResult = await promiseResolver(
      translatedCoursesPromise,
    );
    await promiseResolver(splitInitializationAsPromise);

    if (courseResult.type === 'error') {
      preFetchedData.course = {
        status: REQUEST_STATUS.FAILED,
        data: courseResult.error.message,
      };
      return actions.bootFailed({
        error: courseResult.error.message,
        originalError: courseResult.error.originalError,
        preFetchedData,
      });
    }
    const course = courseResult.data;
    preFetchedData.course = {
      status: REQUEST_STATUS.SUCCESS,
      data: course,
    };

    if (chapterResult.type === 'error') {
      preFetchedData.chapter = {
        status: REQUEST_STATUS.FAILED,
        data: chapterResult.error.message,
      };
      return actions.bootFailed({
        error: chapterResult.error.message,
        originalError: chapterResult.error.originalError,
        preFetchedData,
      });
    }
    const chapter = chapterResult.data;
    preFetchedData.chapter = {
      status: REQUEST_STATUS.SUCCESS,
      data: chapter,
    };

    preFetchedData.workspaceTemplate = {
      status: REQUEST_STATUS.SUCCESS,
      data:
        workspaceTemplateResult.type === 'ok'
          ? workspaceTemplateResult.data
          : null,
    };

    preFetchedData.categoryPages = {
      status: REQUEST_STATUS.SUCCESS,
      data: categoryPagesResult.type === 'ok' ? categoryPagesResult.data : null,
    };

    preFetchedData.translatedCourses = {
      status: REQUEST_STATUS.SUCCESS,
      data:
        translatedCoursesResult.type === 'ok'
          ? translatedCoursesResult.data
          : null,
    };

    if (!config.isServerSideRendering) {
      preFetchedData.learningRecap = {
        status: REQUEST_STATUS.SUCCESS,
        data:
          learningRecapResult.type === 'ok' ? learningRecapResult.data : null,
      };
    }

    if (exercisesResult.type === 'error') {
      preFetchedData.exercises = {
        status: REQUEST_STATUS.FAILED,
        data: exercisesResult.error.message,
      };
      return actions.bootFailed({
        error: exercisesResult.error.message,
        originalError: exercisesResult.error.originalError,
        preFetchedData,
      });
    }
    const exercises = exercisesResult.data;
    preFetchedData.exercises = {
      status: REQUEST_STATUS.SUCCESS,
      data: exercises,
    };

    if (isLtiErrorResult.type === 'error') {
      return actions.bootFailed({
        error: BOOT_ERRORS.UNKNOWN,
        originalError: isLtiErrorResult.error,
        preFetchedData,
      });
    }

    if (isLtiErrorResult.data) {
      return actions.bootFailed({
        error: BOOT_ERRORS.LTI_NOT_ACTIVE,
        originalError: null,
        preFetchedData,
      });
    }

    if (progressResult.type === 'error') {
      return actions.bootFailed({
        error: BOOT_ERRORS.UNKNOWN,
        originalError: progressResult.error,
        preFetchedData,
      });
    }

    const { chapterProgress, courseProgress, streakInfo } = progressResult.data;

    if (courseImagesResult.type === 'error') {
      return actions.bootFailed({
        error: BOOT_ERRORS.UNKNOWN,
        originalError: courseImagesResult.error,
        preFetchedData,
      });
    }
    const courseImages = courseImagesResult.data;
    preFetchedData.courseImages = {
      status: REQUEST_STATUS.SUCCESS,
      data: courseImages,
    };

    if (userResult.type === 'error') {
      return actions.bootFailed({
        error: BOOT_ERRORS.UNKNOWN,
        originalError: userResult.error,
        preFetchedData,
      });
    }
    const user = userResult.data;

    let canonical = null;

    const contentRef = {
      chapterRef: getRef(chapter),
      courseRef: getRef(course),
    };
    const pathname = formatPrettyPath(contentRef, {
      isPreviewMode: action.isPreview,
      prependCampusSubfolder:
        !config.isServerSideRendering &&
        window.location.pathname.startsWith('/campus/'),
    });

    if (
      originalLocation.pathname !== pathname ||
      !isEqual({ ex: exerciseNumber.toString() }, originalLocation.params) ||
      !config.isProductionDomain()
    ) {
      canonical = [
        'https://campus.datacamp.com',
        language === DEFAULT_LANGUAGE ? '' : `/${language}`,
        pathname,
        `?ex=${exerciseNumber}`,
      ].join('');
    }

    return actions.succeedBoot({
      courseId: course.id,
      chapterId: chapter.id,
      exerciseNumber,
      language,
      learningRecap:
        learningRecapResult.type === 'ok' ? learningRecapResult.data : null,
      entities: {
        images: courseImages,
        course,
        exercises,
        user,
        workspaceTemplate:
          workspaceTemplateResult.type === 'ok'
            ? workspaceTemplateResult.data
            : null,
        courseProgress,
        chapterProgress,
        streakInfo,
      },
      pathname,
      // @ts-expect-error Type '{ ex: string; ff?: Partial<any>; }' is not assignable to type 'Record<string, string>'.
      queryParams: {
        ...omit(action.queryParams, ['ff']),
        ...(isEmpty(overwrittenFeatureFlags)
          ? {}
          : { ff: overwrittenFeatureFlags }),
        ex: String(exerciseNumber),
      },
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'boolean'.
      canonical,
      preFetchedData,
    });
  });

export const epicBootFailed = (action$: any) =>
  action$
    .ofType(actions.BOOT_FAILED)
    .filter((action: any) => action.error === BOOT_ERRORS.UNKNOWN)
    .do(({ originalError }: any) => {
      if (!isNil(originalError)) {
        raven.captureException(originalError);
      }
    })
    .concatMapTo([]);

// @ts-expect-error ts-migrate(6133) FIXME: 'store' is declared but its value is never read.
export const epicBootSucceeded = (action$: any, store: any, history: any) =>
  action$
    .ofType(actions.BOOT_SUCCEEDED)
    .do(({ pathname, queryParams }: any) => {
      const newPath = `${pathname}?${qs.stringify(queryParams)}`;
      const { location } = history;
      if (newPath !== `${location.pathname}${location.search}`) {
        history.replace(newPath);
      }
    })
    .concatMapTo([]);
