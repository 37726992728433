import { Map as hashMap } from 'immutable';

import { isAiFeatureEnabled } from '../../helpers/ai/featureConfig';
import { isFeatureEnabled } from '../../helpers/featureFlags';
import type { State } from '../../interfaces/State';

import { selectCourseId } from './course';
import { selectExerciseOrSubExType } from './exercises';

export const USER_STATUS = {
  LOGIN: 'login',
  UNAUTHORIZED: 'unauthorized',
  NOT_INITIATE: 'not_initiate',
  PENDING: 'pending',
  ERROR: 'error',
};

export const selectUser = (state: any): any => state.get('user') || hashMap();
export const isUserFromDatacamp = (state: any): boolean => {
  const userGroup = selectUser(state).getIn(
    ['settings', 'current_group_name'],
    '',
  );
  return userGroup ? userGroup.includes('DataCamp') : false;
};
export const selectUserIsPaid = (state: any): boolean => state.get;
export const selectUserSettings = (state: any): any =>
  selectUser(state).get('settings') || hashMap();
export const selectIsUserLoggedIn = (state: any): boolean =>
  Boolean(selectUserSettings(state).get('id'));
export const selectIsUserNotLoggedIn = (state: any): boolean =>
  selectUser(state).get('status') === USER_STATUS.UNAUTHORIZED;
export const selectIsUserStatusError = (state: any): boolean =>
  selectUser(state).get('status') === USER_STATUS.ERROR;
export const selectMultiplexerUrl = (state: any): string =>
  selectUserSettings(state).get('multiplexerUrl');
export const selectUserIsInEnterpriseGroup = (state: State): boolean => {
  const userGroups = selectUserSettings(state).get('groups', []);
  const isActiveB2bGroup = (group: any): boolean =>
    group.get('type') === 'enterprise' && group.get('status') !== 'cancelled';
  return userGroups.some(isActiveB2bGroup);
};
export const selectUserLanguage = (state: State): string =>
  selectUserSettings(state).get('language', 'en-US');
export const selectUserIsEnabledForAiErrorExplanation = (
  state: State,
): boolean => {
  const aiAssistantEnabled = isFeatureEnabled('exp_wl_explain_error_button');
  const isAiAssistantEnabledForGroups = selectUserSettings(state).getIn(
    ['aiFlags', 'aiErrorExplanationEnabled'],
    false,
  );
  return aiAssistantEnabled && isAiAssistantEnabledForGroups;
};

export const selectUserIsEnabledForAiIncorrectSubmissions = (
  state: State,
): boolean => {
  const courseId = selectCourseId(state);
  const exerciseType = selectExerciseOrSubExType(state);
  const isB2BFlagEnabled = selectUserSettings(state).getIn(
    ['aiFlags', 'aiErrorExplanationEnabled'],
    false,
  );
  const isUserB2B = selectUserIsInEnterpriseGroup(state);
  const fromDatacamp = isUserFromDatacamp(state);
  return isAiFeatureEnabled({
    courseId,
    exerciseType,
    feature: 'aiHints',
    isB2BFlagEnabled,
    isUserB2B,
    isUserFromDatacamp: fromDatacamp,
  });
};

export const selectUserIsEnabledForAIExplainSolution = (
  state: State,
): boolean => {
  const isUserInEnterpriseGroup = selectUserIsInEnterpriseGroup(state);
  const fromDatacamp = isUserFromDatacamp(state);
  const b2c = !isUserInEnterpriseGroup;

  const b2b_enabled = selectUserSettings(state).getIn(
    ['aiFlags', 'aiSolutionExplanationEnabled'],
    false,
  );

  if (b2c || b2b_enabled || fromDatacamp) {
    const isSolutionExplanationEnabled = isFeatureEnabled(
      'cp--explain-solution',
    );
    return isSolutionExplanationEnabled;
  }
  return false;
};

export const selectUserIsEnabledForLearningRecap = (state: State): boolean => {
  const isUserB2c = !selectUserIsInEnterpriseGroup(state);
  const isFromDatacamp = isUserFromDatacamp(state);

  // Feature flag check is verified once the user is eligible for learn recap
  return isUserB2c || isFromDatacamp;
};

export type TrackingGeneratorUser = {
  activeProducts?: string[];
  email?: string;
  id?: number;
  inEnterpriseGroup?: boolean;
  language?: string;
  loggedIn?: boolean;
  notLoggedIn?: boolean;
  phone?: string;
  statusError?: boolean;
};

export const selectTrackingGeneratorUser = (
  state: State,
): TrackingGeneratorUser => ({
  activeProducts: selectUserSettings(state).get('active_products'),
  email: selectUserSettings(state).get('email'),
  id: selectUserSettings(state).get('id'),
  inEnterpriseGroup: selectUserIsInEnterpriseGroup(state),
  language: selectUserSettings(state).get('language'),
  loggedIn:
    selectUser(state).get('status') == null
      ? undefined
      : selectIsUserLoggedIn(state),
  notLoggedIn:
    selectUser(state).get('status') == null
      ? undefined
      : selectIsUserNotLoggedIn(state),
  phone: selectUserSettings(state).get('phone'),
  statusError:
    selectUser(state).get('status') == null
      ? undefined
      : selectIsUserStatusError(state),
});

export function selectHasSeenCampusTour(state: State): boolean {
  return selectUserSettings(state).get('has_seen_campus_tour') ?? false;
}
