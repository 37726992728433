import type { Reducer } from '../../../../interfaces/State';
import type {
  ExerciseCompletedAction,
  StreakInfoResponse,
} from '../../../actions';
import {
  BOOT_SUCCEEDED,
  EXERCISE_COMPLETED,
  HIDE_DAILY_STREAK_SCREEN,
  SHOW_DAILY_STREAK_SCREEN,
  STREAK_INFO_UPDATED,
} from '../../../actions';
import type {
  StreakInfo,
  StreakInfoKnownStateV2,
  StreakInfoState,
} from '../types';
import { StreakInfoType } from '../types';

const initialState: StreakInfoState = { type: StreakInfoType.Unknown };

function updateStreakInfo(
  state: StreakInfoState,
  streakInfo?: StreakInfoResponse | null,
): StreakInfoState {
  if (!streakInfo) {
    return state;
  }

  const {
    daily_xp: dailyXp,
    streak_goal: streakGoal,
    streak_state_after_goal_met: next,
    ...current
  } = streakInfo;

  return {
    version: 2,
    streakGoalMetInSession: false,
    screenVisible: false,
    type: StreakInfoType.Known,
    streakGoal,
    dailyXp,
    current,
    next,
  };
}

function handleXPIncrease(
  state: StreakInfoKnownStateV2,
  action: ExerciseCompletedAction,
): StreakInfoKnownStateV2 {
  const { dailyXp } = state;

  const newDailyXp = dailyXp + action.xpGained;

  return {
    ...state,
    dailyXp: newDailyXp,
  };
}

function handleDailyGoalReached(
  state: StreakInfoKnownStateV2,
  action: ExerciseCompletedAction,
): StreakInfoKnownStateV2 {
  const { current, dailyXp, next } = state;

  const newDailyXp = dailyXp + action.xpGained;

  const updatedCurrent: StreakInfo = {
    ...current,
    ...next,
    streak: {
      ...current.streak,
      ...next?.streak,
      last_incremented_at: new Date(
        action.currentTime || Date.now(),
      ).toISOString(),
    },
  };

  return {
    ...state,
    streakGoalMetInSession: true,
    dailyXp: newDailyXp,
    current: updatedCurrent,
    next: null,
  };
}

function handleExerciseCompletedBeforeDeadline(
  state: StreakInfoKnownStateV2,
  action: ExerciseCompletedAction,
) {
  const { current, dailyXp, streakGoal } = state;

  const newDailyXp = dailyXp + action.xpGained;
  const hasMetDailyGoal = !current.streak_goal_met && newDailyXp >= streakGoal;

  return hasMetDailyGoal
    ? handleDailyGoalReached(state, action)
    : handleXPIncrease(state, action);
}

const handeExerciseCompleted = (
  state: StreakInfoKnownStateV2,
  action: ExerciseCompletedAction,
): StreakInfoKnownStateV2 => {
  const didMissCurrentDeadline =
    action.currentTime > Date.parse(state.current.streak_deadline);

  if (didMissCurrentDeadline) {
    // When missing the deadline, this means the network request has failed,
    //  so we just update the XP counter to avoid having to duplicate logic from main-app here
    return handleXPIncrease(state, action);
  }

  return handleExerciseCompletedBeforeDeadline(state, action);
};

function handleShowDailyStreakScreen(state: StreakInfoKnownStateV2) {
  return {
    ...state,
    screenVisible: true,
  };
}

function handleHideDailyStreakScreen(state: StreakInfoKnownStateV2) {
  if (!state.screenVisible) {
    return state;
  }

  return {
    ...state,
    screenVisible: false,
    // Hiding the daily streak screen also dismisses the goal met flag
    // to prevent showing the screen multiple times
    streakGoalMetInSession: false,
  };
}

const streakInfoReducer: Reducer<StreakInfoState> = (
  state = initialState,
  action,
) => {
  if (action.type === BOOT_SUCCEEDED) {
    return updateStreakInfo(state, action.entities.streakInfo);
  }

  if (state.type === StreakInfoType.Unknown || state.version !== 2) {
    return state;
  }

  switch (action.type) {
    case STREAK_INFO_UPDATED:
      return updateStreakInfo(state, action.data);
    case EXERCISE_COMPLETED:
      return handeExerciseCompleted(state, action);
    case SHOW_DAILY_STREAK_SCREEN:
      return handleShowDailyStreakScreen(state);
    case HIDE_DAILY_STREAK_SCREEN:
      return handleHideDailyStreakScreen(state);
    default:
      return state;
  }
};

export default streakInfoReducer;
